<template>
  <div>
    <NuxtLink :to="localePathByType('ArticleRecord', data.slug as string)" noPrefetch>
      <Image
        v-if="data.image"
        class="aspect-3/2 object-cover"
        loading="lazy"
        :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
        :srcset="data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined"
        :width="data.image.responsiveImage ? data.image.responsiveImage.width : data.image.width"
        :height="data.image.responsiveImage ? data.image.responsiveImage.height : data.image.height"
        :alt="data.image.alt ? data.image.alt : ''"
      />
    </NuxtLink>
    <div class="mb-1 mt-6 flex justify-between">
      <span class="flow-root">
        <NuxtLink
          noPrefetch
          v-for="category in data.category"
          :key="category.id"
          :to="
            localePath({
              path: '/' + paths[locale as LocaleCode][ModelApiKey.Article].path,
              query: { category: category.name }
            })
          "
          class="mb-2 mr-2 inline-block bg-black px-2 py-2.5 text-sm-spacing text-white no-underline"
          >{{ category.name }}</NuxtLink
        >
      </span>
      <Button
        noPrefetch
        isType="circle"
        icon="arrow-right"
        :to="localePathByType('ArticleRecord', data.slug as string)"
        :title="$t('articles.read')"
      />
    </div>
    <NuxtLink
      noPrefetch
      :to="localePathByType('ArticleRecord', data.slug) as string"
      class="text-lg-semibold font-bold lg:text-2xl"
    >
      {{ data.title }}</NuxtLink
    >
  </div>
</template>

<script setup lang="ts">
import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'

const { locale } = useI18n()
const localePath = useLocalePath()
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
