<template>
  <div class="relative mb-6 py-14 lg:mb-12 lg:py-20">
    <div class="absolute left-1/2 top-0 h-full w-screen -translate-x-1/2 bg-white2"></div>
    <div class="relative grid grid-cols-6 gap-x-4 gap-y-10 lg:grid-cols-12 lg:gap-x-8 lg:gap-y-0">
      <div class="col-span-6">
        <NuxtLink :to="localePathByType('ArticleRecord', data.slug) as string" noPrefetch>
          <Image
            v-if="data.image"
            class="aspect-3/2 object-cover"
            :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
            :srcset="data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined"
            :width="data.image.responsiveImage ? data.image.responsiveImage.width : data.image.width"
            :height="data.image.responsiveImage ? data.image.responsiveImage.height : data.image.height"
            :alt="data.image.alt ? data.image.alt : ''"
          />
        </NuxtLink>
      </div>
      <div class="col-span-6 xl:col-span-5 xl:col-start-8">
        <div class="mb-2 flow-root lg:pr-4">
          <NuxtLink
            v-for="category in data.category"
            :key="category.id"
            :to="localeRoute({ path: $route.path, query: { category: category.name } })"
            class="mb-2 mr-2 inline-block bg-black px-2 py-2.5 text-sm-spacing text-white no-underline"
            >{{ category.name }}</NuxtLink
          >
        </div>
        <div class="mb-4 lg:mb-6 lg:pr-4">
          <NuxtLink
            :to="localePathByType('ArticleRecord', data.slug) as string"
            noPrefetch
            class="font-gt-flexa-light text-2xl lg:text-4xl"
          >
            {{ data.title }}
          </NuxtLink>
        </div>
        <div v-if="data.excerpt" class="mb-6 lg:pr-4" v-html="data.excerpt"></div>
        <Button
          :to="localePathByType('ArticleRecord', data.slug) as string"
          noPrefetch
          :label="$t('articles.read')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const localeRoute = useLocaleRoute()
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
