<template>
  <div>
    <div
      class="relative w-full pb-5"
      :class="isScrollable && 'scrollbar overflow-scroll overflow-y-hidden lg:overflow-hidden'"
    >
      <div
        v-if="isLoading"
        class="absolute left-1/2 top-0 h-[580px] w-screen -translate-x-1/2 bg-white2"
      ></div>
      <div
        class="grid grid-cols-6 gap-4 lg:grid-cols-12 lg:gap-8"
        :class="isScrollable ? 'min-w-[880px]' : null"
      >
        <template v-for="(item, idx) in data" :key="idx">
          <ArticleListFeaturedItem
            v-if="Number(idx) === 0 && showFeatured"
            :data="item"
            :class="'col-span-6 lg:col-span-12'"
          />
          <ArticleListItem
            v-else
            :data="item"
            :class="
              isScrollable
                ? 'col-span-2 lg:col-span-4'
                : 'col-span-6 mb-10 sm:col-span-3 lg:col-span-4 lg:mb-20'
            "
          />
        </template>
      </div>
    </div>

    <div v-if="isLoading" class="py-10 text-center">
      <Spinner class="mx-auto mt-10" />
    </div>

    <div v-else-if="loadMore" class="mb-14 text-center lg:mb-20">
      <Button :label="loadMoreLabel" isType="secondary" @click="store.fetchArticles()" />
    </div>
    <div v-else-if="isEmpty">
      <div class="my-10 text-center text-4xl">{{ $t('articles.empty') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBlogStore } from '../stores/BlogStore'

const store = useBlogStore()

defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  loadMore: {
    type: Boolean,
    default: false
  },
  loadMoreLabel: {
    type: String,
    default: 'Load more'
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  isScrollable: {
    type: Boolean,
    default: false
  },
  showFeatured: {
    type: Boolean,
    default: false
  }
})
</script>
